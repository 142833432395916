import React, { Component } from "react"
import {
  okAutoLogoNewBlack,
  arrowDown,
  paperOne,
  tyre,
  eraseIcon,
  shoppingCart,
  successedImg,
} from "../assets"
import { Layout, SEO } from "../components"
import InputMask from "react-input-mask"
import NumberFormat from "react-number-format"
import "./main.css"
import "./order.css"
import qs from "query-string"
import ApolloClient from "apollo-boost"
import gql from "graphql-tag"
import fetch from "isomorphic-fetch"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

const client = new ApolloClient({
  uri: process.env.API_LIVE_URL,
  fetch,
})


const weightIndexDescription = {
  60: 250,
  61: 257,
  62: 265,
  63: 272,
  64: 280,
  65: 290,
  66: 300,
  67: 307,
  68: 315,
  69: 325,
  70: 335,
  71: 345,
  72: 355,
  73: 365,
  74: 375,
  75: 387,
  76: 400,
  77: 412,
  78: 425,
  79: 437,
  80: 450,
  81: 462,
  82: 475,
  83: 487,
  84: 500,
  85: 515,
  86: 530,
  87: 545,
  88: 560,
  89: 580,
  90: 600,
  91: 615,
  92: 630,
  93: 650,
  94: 670,
  95: 690,
  96: 710,
  97: 730,
  98: 750,
  99: 775,
  100: 800,
  101: 825,
  102: 850,
  103: 875,
  104: 900,
  105: 925,
  106: 950,
  107: 975,
  108: 1000,
  109: 1030,
  110: 1060,
  111: 1090,
  112: 1120,
  113: 1150,
  114: 1180,
  115: 1215,
  116: 1250,
  117: 1285,
  118: 1320,
  119: 1360,
  120: 1400,
  121: 1450,
  122: 1500,
  123: 1550,
  124: 1600,
  125: 1650,
  126: 1700,
  127: 1750,
  128: 1800,
  129: 1850,
  130: 1900,
  131: 1950,
  132: 2000,
  133: 2060,
  134: 2120,
  135: 2180,
  136: 2240,
  137: 2300,
  138: 2360,
  139: 2430,
  140: 2500,
}
const velocityIndexDescription = {
  K: 110,
  L: 120,
  M: 130,
  N: 140,
  P: 150,
  Q: 160,
  R: 170,
  S: 180,
  T: 190,
  U: 200,
  H: 210,
  V: 240,
  W: 270,
  Y: 300,
}

const initialState = {
  showDetails: false,
  checked: true,
  inputs: {},
  errors: {},
  phoneNumber: "",
  quantity: 4,
  showShoppingCart: false,
  opacity: 1,
  sum: 0,
  showModal: false,
  showEraseBtn: false,
  extraPayload: null,
  productEcarId: null,
  fingerprintInBase64: null,
  intervalId: null,
  tyre: null,
}

class OrderTyres extends Component {
  state = initialState

  componentWillMount = async () => {
    const intervalId = setInterval(() => {
      if (window.MPWalletFingerprint !== "undefined") {
        clearInterval(this.state.intervalId)
        window.MPWalletFingerprint.getFingerprint().then(
          result => {
            this.setState({
              fingerprintInBase64: result,
            })
          },
          err => {
            console.error(err)
          }
        )
      }
    }, 400)
    this.setState({ intervalId })
    const query = qs.parse(window.location.search)
    const productEcarIds = (() => {
      if (Array.isArray(query.id)) {
        return query.id
      }

      return [query.id]
    })()

    const res = await client.query({
      variables: {
        input: {
          productEcarIds: productEcarIds,
        },
      },
      query: gql`
        query ecarTyres($input: EcarTyresInput = { productEcarIds: [] }) {
          ecarTyres(input: $input) {
            id
            brandName
            modelName
            season
            width
            height
            diameter
            weightSingleIndex
            velocityIndex
            manufacturerCountry
            priceInCents
            priceWithDiscountInCents
            quantityAvailible
            isRunFlat
          }
        }
      `,
    })

    this.setState({
      extraPayload: query.extraPayload,
      productEcarId: query.id,
      tyre: res.data.ecarTyres,
      sum:
        (this.state.quantity * res.data.ecarTyres.priceWithDiscountInCents) /
        100,
    })
  }

  componentDidMount() {
    if (window.document.body.offsetHeight > window.innerHeight + 64) {
      this.setState({ showShoppingCart: true })
    }
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    let scroll =
      window.document.body.offsetHeight -
      (window.innerHeight + window.pageYOffset)

    this.setState({ opacity: scroll / 100 })
  }

  toggleDetails = () => {
    this.setState(
      {
        showDetails: !this.state.showDetails,
      },
      function() {
        this.setState({
          showShoppingCart:
            window.document.body.offsetHeight > window.innerHeight + 64
              ? true
              : false,
        })
      }
    )
  }

  renderTitle = () => {
    if (this.state.tyre) {
      return `${this.state.tyre.brandName || ""} ${this.state.tyre.modelName ||
        ""}`
    }

    return ""
  }

  renderSizes = () => {
    if (this.state.tyre) {
      return `${this.state.tyre.width || ""}/${this.state.tyre.height ||
        ""} R${this.state.tyre.diameter || ""}`
    }

    return ""
  }

  renderSeason = () => {
    if (
      this.state.tyre &&
      this.state.tyre.season &&
      this.state.tyre.season.toLowerCase() === "winter"
    ) {
      return "Зимние"
    }
    if (
      this.state.tyre &&
      this.state.tyre.season &&
      this.state.tyre.season.toLowerCase() === "allseason"
    ) {
      return "Всесезонные"
    }
    if (
      this.state.tyre &&
      this.state.tyre.season &&
      this.state.tyre.season.toLowerCase() === "summer"
    ) {
      return "Летние"
    }

    return ""
  }

  renderWeightIndex = () => {
    if (this.state.tyre && this.state.tyre.weightSingleIndex) {
      return `${this.state.tyre.weightSingleIndex} (${
        weightIndexDescription[this.state.tyre.weightSingleIndex]
      } кг)`
    }

    return ""
  }

  renderVelocityIndex = () => {
    if (this.state.tyre && this.state.tyre.velocityIndex) {
      return `${this.state.tyre.velocityIndex} (${
        velocityIndexDescription[this.state.tyre.velocityIndex]
      } км/ч)`
    }

    return ""
  }

  renderSubTitle = () => {
    if (this.state.tyre) {
      return `${this.renderSeason()} ${this.renderSizes()} ${this.state.tyre
        .weightSingleIndex || ""}${this.state.tyre.velocityIndex || ""} ${this
        .state.tyre.manufacturerCountry || ""}`
    }

    return `${this.renderSeason()} ${this.renderSizes()}  `
  }

  renderPrice = () => {
    if (this.state.tyre && this.state.tyre.priceWithDiscountInCents) {
      return (
        <div className="mb2">
          <span className="o-text o-text--bordered dib mr2">
            {this.state.tyre.priceWithDiscountInCents / 100} ₸
          </span>
          {this.state.tyre.priceInCents !==
          this.state.tyre.priceWithDiscountInCents ? (
            <span className="f7 lh-title dib o-text--crossed">
              {this.state.tyre.priceInCents / 100} ₸
            </span>
          ) : null}
        </div>
      )
    }

    return (
      <div className="mb2">
        <span className="o-text o-text--bordered dib mr2">-- ₸</span>
        <span className="f7 lh-title dib o-text--crossed">-- ₸</span>
      </div>
    )
  }

  renderAvailableQuantity = () => {
    if (this.state.tyre && this.state.tyre.quantityAvailible) {
      return `${this.state.tyre.quantityAvailible} шин`
    }

    return "0 шин"
  }

  renderRunFlat = () => {
    if (this.state.tyre) {
      return this.state.tyre.isRunFlat ? "Да" : "Нет"
    }

    return ""
  }

  chooseQuantity = event => {
    const target = event.target
    const action = target.getAttribute("data-name")
    const quantity = (() => {
      if (action === "decrease" && this.state.quantity > 1) {
        return this.state.quantity - 1
      }
      if (
        action === "increase" &&
        this.state.quantity <
          (this.state.tyre ? this.state.tyre.quantityAvailible : 4)
      ) {
        return this.state.quantity + 1
      }

      return this.state.quantity
    })()
    const sum =
      quantity *
      (this.state.tyre ? this.state.tyre.priceWithDiscountInCents / 100 : 0)

    this.setState({
      quantity,
      sum,
    })
  }

  handleChange = () => {
    this.setState({ checked: !this.state.checked })
  }

  handleInput = event => {
    let target = event.target

    this.setState(({ inputs }) => ({
      inputs: {
        ...inputs,
        [target.name]: target.value,
      },
      phoneNumber: target.value,
    }))
  }

  onFocus = () => {
    this.setState({ showEraseBtn: true })
  }

  onBlur = event => {
    let target = event.target

    !target.value && this.setState({ showEraseBtn: false })
  }

  inputValidation = () => {
    let inputs = this.state.inputs
    let errors = {}
    let formIsValid = true

    if (!inputs["phoneNumber"] || inputs["phoneNumber"].slice(-1) === " ") {
      formIsValid = false
      errors["phoneNumber"] = "Введите корректный номер телефона"
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  handleSubmit = async event => {
    event.preventDefault()
    if (this.inputValidation() && this.state.fingerprintInBase64) {
      await client.mutate({
        variables: {
          input: {
            extraPayload: this.state.extraPayload,
            clientPhone: this.state.inputs["phoneNumber"],
            quantity: this.state.quantity,
            productEcarId: this.state.tyre.id,
            fingerprintInBase64: this.state.fingerprintInBase64,
          },
        },
        mutation: gql`
          mutation tyresSpecialOfferCreate(
            $input: TyresSpecialOfferCreateInput = {
              extraPayload: ""
              clientPhone: ""
              quantity: 0
              productEcarId: ""
              fingerprintInBase64: ""
            }
          ) {
            tyresSpecialOfferCreate(input: $input) {
              success
            }
          }
        `,
      })
      this.setState({ showModal: true })
    }
  }

  clearInput = () => {
    this.setState({
      phoneNumber: "",
      showEraseBtn: false,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <Layout version="order">
        <Helmet>
          <script src={withPrefix("js/05. Web_SDK.min.js")} />
        </Helmet>
        <SEO title="" description="" />
        <div className="ph3 pt3 pb4">
          <div className="mb2">
            <a className="" href="/">
              <img
                className="mb0"
                src={okAutoLogoNewBlack}
                alt="Logo"
                title="Logo"
              />
            </a>
          </div>

          <div className="flex flex-row mb3">
            <div className="mr4">
              <img className="mb0" src={tyre} />
            </div>

            <div className="mb1">
              <p className="o-text mb1">{this.renderTitle()}</p>
              <p className="f7 gray lh-title mb3">{this.renderSubTitle()}</p>
              {this.renderPrice()}
              <p className="f7 gray lh-title mb0">
                Цена за штуку, {this.renderAvailableQuantity()} в наличии
              </p>
            </div>
          </div>

          <div className="o-border mb3" onClick={this.toggleDetails}>
            <div className="pa3 flex flex-row justify-between pointer">
              <img src={paperOne} className="mb0 mr2" />
              <span className="flex-grow-1 o-text">Характеристики</span>
              <img
                src={arrowDown}
                className={
                  this.state.showDetails
                    ? "mb0 pointer o-arrow o-arrow--opened"
                    : "mb0 pointer o-arrow"
                }
              />
            </div>
            <div
              className={
                this.state.showDetails ? "pa3 pt2 o-border--top" : "dn"
              }
            >
              <div className="overflow-hidden mb2">
                <span className="fl w-50 f6 gray">Размеры</span>
                <span className="fl w-50 f6">{this.renderSizes()}</span>
              </div>
              <div className="overflow-hidden mb2">
                <span className="fl w-50 f6 gray">Индекс нагрузки</span>
                <span className="fl w-50 f6">{this.renderWeightIndex()}</span>
              </div>
              <div className="overflow-hidden mb2">
                <span className="fl w-50 f6 gray">Индекс скорости</span>
                <span className="fl w-50 f6">{this.renderVelocityIndex()}</span>
              </div>
              <div className="overflow-hidden mb2">
                <span className="fl w-50 f6 gray">Сезон</span>
                <span className="fl w-50 f6">{this.renderSeason()}</span>
              </div>
              <div className="overflow-hidden mb2">
                <span className="fl w-50 f6 gray">Бренд</span>
                <span className="fl w-50 f6">
                  {this.state.tyre ? this.state.tyre.brandName : "Hifly"}
                </span>
              </div>
              <div className="overflow-hidden mb2">
                <span className="fl w-50 f6 gray">Модель</span>
                <span className="fl w-50 f6">
                  {this.state.tyre
                    ? this.state.tyre.modelName
                    : "Vigorous HP801"}
                </span>
              </div>
              <div className="overflow-hidden">
                <span className="fl w-50 f6 gray">RunFlat</span>
                <span className="fl w-50 f6">{this.renderRunFlat()}</span>
              </div>
            </div>
          </div>

          <form onSubmit={this.handleSubmit} className="ma0">
            <div className="o-border pv2 ph3 flex flex-row justify-between mb3">
              <span
                className={
                  this.state.quantity === 1
                    ? "o-button o-button--minus o-button--disabled dib tc"
                    : "o-button o-button--minus dib tc"
                }
                data-name="decrease"
                onClick={this.chooseQuantity}
              ></span>
              <p className="f7 gray lh-title tc mb0">
                Сколько нужно шин
                <span className="db o-text black">{this.state.quantity}</span>
              </p>
              <span
                className={
                  this.state.quantity ===
                  (this.state.tyre ? this.state.tyre.quantityAvailible : 4)
                    ? "o-button o-button--plus o-button--disabled dib tc"
                    : "o-button o-button--plus dib tc"
                }
                data-name="increase"
                onClick={this.chooseQuantity}
              ></span>
              <input
                type="hidden"
                name="quantity"
                value={this.state.quantity}
              />
            </div>

            <div className="o-phone mb1">
              <InputMask
                mask="+7 999 999 99 99"
                name="phoneNumber"
                placeholder="Номер телефона"
                maskChar=" "
                className={
                  this.state.errors["phoneNumber"]
                    ? "o-input o-input--error outline-0 bn pa3 db w-100"
                    : "o-input outline-0 bn pa3 db w-100"
                }
                inputMode="tel"
                value={this.state.phoneNumber}
                onChange={this.handleInput}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
              />
              <img
                className="absolute pointer mb0 o-erase"
                src={eraseIcon}
                alt="Erase icon"
                title="Erase icon"
                onClick={this.clearInput}
                style={{
                  display: this.state.showEraseBtn ? "block" : "none",
                }}
              />
            </div>
            <p
              className={
                this.state.errors["phoneNumber"]
                  ? "f7 lh-title gray mb3 o-error"
                  : "f7 lh-title gray mb3"
              }
            >
              {this.state.errors["phoneNumber"]
                ? this.state.errors["phoneNumber"]
                : "С вами свяжется наш менеджер для уточнения информации"}
            </p>

            <div className="o-checkbox mb3">
              <input
                type="checkbox"
                id="agreeTerms"
                name="agreeTerms"
                defaultChecked={this.state.checked}
                onChange={this.handleChange}
              />
              <label htmlFor="agreeTerms" className="f7 lh-solid">
                Разрешаю сбор, обработку и передачу данных и служебной
                информации партнёру OKauto
              </label>
            </div>

            <button
              id="order_button"
              className={
                this.state.checked
                  ? "o-submit o-submit--active w-100 outline-0 bn pointer mb3"
                  : "o-submit w-100 outline-0 bn pointer mb3"
              }
              type="submit"
            >
              Заказать за{" "}
              <NumberFormat
                value={this.state.sum}
                displayType={"text"}
                thousandSeparator={" "}
              />{" "}
              ₸
            </button>
          </form>
          <a
            href="#order_button"
            className={this.state.showShoppingCart ? "dib fixed o-cart" : "dn"}
            style={{ opacity: this.state.opacity }}
          >
            <img
              className="mb0"
              src={shoppingCart}
              alt="Shoppping Cart"
              title="Shopping Cart"
            />
          </a>
        </div>
        <div
          className="o-modal fixed bottom-0 right-0 top-0 left-0 bg-white"
          style={{ display: this.state.showModal ? "block" : "none" }}
        >
          <div className="pa3 pt4 h-100 flex flex-column justify-between">
            <p className="o-modal__text tc">Успешно оформлено!</p>
            <img src={successedImg} className="mb0 db center" />
            <a
              href="https://closePromoUrl"
              className="o-submit o-submit--active db w-100 tc no-underline pt2"
            >
              Закрыть
            </a>
          </div>
        </div>
      </Layout>
    )
  }
}

export default OrderTyres
